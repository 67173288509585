import React, { useState, useEffect } from "react";
import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";

const Banner = () => {
  const [closed, setClosed] = useState(false);
  useEffect(() => {
    const modalbg = document.getElementById("modal-bg");
    const modalSwitch = document.getElementById("modal-switch");
    const modalBox = document.getElementById("modal-box");
    const modalClose = document.getElementById("modal-close");
    modalbg.addEventListener("click", function () {
      modalBox.classList.add("hidden");
      modalbg.classList.add("hidden");
    });
    modalSwitch.addEventListener("click", function () {
      modalBox.classList.remove("hidden");
      modalbg.classList.remove("hidden");
    });
    modalClose.addEventListener("click", function () {
      modalBox.classList.remove("hidden");
      modalbg.classList.remove("hidden");
    });
  }, []);

  const closehandler = () => {
    setClosed(!closed);
  };

  return (
    <div className="banner">
      {closed ? (
        <div className="">hi</div>
      ) : (
        <div className="bg-indigo-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-indigo-800">
                  <SpeakerphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">
                    We're are excited to announce Techsonance 2023, Come Join
                    hands with us..!!🤝🔥🔥
                  </span>
                  <span className="hidden md:inline">
                    Big news! We're are excited to announce Techsonance 2023,
                    Come Join hands with us..!!🤝🔥🔥
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href="#"
                  id="modal-switch"
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
                >
                  Lessgoo!!!🚀🚀
                </a>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 cursor-pointer flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  {/* <span className="sr-only">Dismiss</span> */}
                  <div className="" onClick={closehandler}>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
